import { graphql, useStaticQuery } from 'gatsby';

interface UseSiteMetadataProps {
  siteUrl?: string;
  companyName?: string;
  shortName?: string;
  googleVerification?: string;
  pinterestVerification?: string;
  metaTitleFallback?: string;
  twitter?: string;
  footerTerms?: string;
  contactNumber?: string;
  agriaContactNumber?: string;
  trustpilotUrl?: string;
  isFreeJourney?: boolean;
  subSpecies?: string;
  schemaData?: string;
  excludedRegions?: string[];
  excessTypeOveride?: 'fixed' | 'variable';
  detailedExcessExplainer?: boolean;
  includeHearFromQuestion?: boolean;
  includeMarketingPreferences?: boolean;
  includeStandAloneAddPetButton?: boolean;
  heroDescription?: string;
  includeTrustPilot?: boolean;
  petDetailsIntroText?: string;
  heroHeading?: string;
  includeDiscountTerms?: boolean;
  tplInProductCard?: boolean;
  useSimpleHeader?: boolean;
  includeWhichLogos?: boolean;
  usePremiumInsteadOfPolicyTotal?: boolean;
  includePaymentMethodInformationBox?: boolean;
  priceInformation?: string;
  priceInformationTitle?: string;
  includePaymentTransactionInformationBox?: boolean;
  includeTwoReviewActionButtons?: boolean;
  nextStepButtonText?: string;
  useWordsForNumbers?: boolean;
}

export const useSiteMetadata = (): UseSiteMetadataProps => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query getSiteMetadata {
      site {
        siteMetadata {
          siteUrl
          companyName
          shortName
          googleVerification
          pinterestVerification
          metaTitleFallback
          twitter
          footerTerms
          contactNumber
          agriaContactNumber
          trustpilotUrl
          isFreeJourney
          affiliateType
          subSpecies
          schemaData
          excludedRegions
          excessTypeOveride
          detailedExcessExplainer
          includeHearFromQuestion
          includeMarketingPreferences
          includeStandAloneAddPetButton
          heroDescription
          includeTrustPilot
          petDetailsIntroText
          heroHeading
          includeDiscountTerms
          tplInProductCard
          useSimpleHeader
          includeWhichLogos
          usePremiumInsteadOfPolicyTotal
          includePaymentMethodInformationBox
          priceInformation
          priceInformationTitle
          includePaymentTransactionInformationBox
          includeTwoReviewActionButtons
          nextStepButtonText
          useWordsForNumbers
        }
      }
    }
  `);

  return siteMetadata;
};
